import { Injectable } from '@angular/core';
import { FinalizedConfigurationDocument } from '@api/models';
import { tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _configuration: Array<FinalizedConfigurationDocument> = [];

  constructor(private apiService: ApiService) {}

  get configurations() {
    return this._configuration;
  }

  getConfigurationForChain(chainId: number) {
    const configuration = this._configuration.find(
      (config) => config.chainId === chainId
    );

    if (!configuration) {
      throw new Error(`Configuration for chainId ${chainId} not found.`);
    }

    return configuration;
  }

  fetchConfiguration() {
    return this.apiService.getConfiguration().pipe(
      tap((configuration) => {
        this._configuration = configuration;
      })
    );
  }
}
